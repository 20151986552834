import React from 'react';
import '../App.css';
import './Lugar.css';

function Lugar() {


  return (
    <div className='Lugar-container' id='Lugar'> 
      <div className='info'>
        <h1>¿Cuándo y dónde?</h1>
        <p>A continuación encontrarás el horario y ubicación de nuesta boda, así como un enlace directo a Google Maps para que te sea más fácil llegar al lugar.</p>   
        <p>
            <u>6 de abril del 2024 </u><br/>
            Ceremonia Civil: 4:45pm<br/>
            Discurso Bíblico: 5:30pm<br/>
            Recepción: 7:00 pm</p>
            <br/>
        <a href="https://www.google.com/maps/place/Princess+Wedding+Gardens/@25.472616,-100.169149,14z/data=!4m6!3m5!1s0x8662cf353c127677:0xd3f48ef2669065!8m2!3d25.4726162!4d-100.1691488!16s%2Fg%2F11b740wyl0?hl=es-419&entry=ttu" className='nombresalon'>Princess Wedding Gardens</a>
            <br/>
      </div>
      
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12115.023302477335!2d-100.17747558356199!3d25.47775278416012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662cf353c127677%3A0xd3f48ef2669065!2sPrincess%20Wedding%20Gardens!5e0!3m2!1ses-419!2smx!4v1703401041344!5m2!1ses-419!2smx"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='responsive-iframe'></iframe>
      
      <div className='direccion'>
        <p>Juarez 100 cruz con Callejón de los Blanco, Los Rodríguez, 67300 Santiago, N.L.</p>
      </div>
      <br/>
    </div>    
    
  );
}

export default Lugar;