import React from 'react';
import '../App.css';
import { Button } from './Button';
import './CivilSection.css';
import videoBg from '../assets/videos/SavetheDatev2.mp4'


  const images  = [
    "../assets/images/img-home.jpeg",
    "../assets/images/img-home2.jpeg",
    "../assets/images/img-home3.jpeg",
    "../assets/images/img-home4.jpeg",
    "../assets/images/img-home5.jpeg",
    "../assets/images/img-home6.jpeg",
    "../assets/images/img-home7.jpeg",
  ];


function ContadorSection() {

  return (
    <div className='Civil-container' id='container'> 
      
      <h1>— N O S &nbsp; C A S A M O S —</h1>
      <p className='nombres'>Abraham & Melissa</p>
      <p>6 · Abril · 2024</p>     
      
    </div>    
    
  );
}

export default ContadorSection;