import React from 'react';
import '../../App.css';
import CivilSection from '../CivilSection';
import ContadorSection from '../Contador';
import Intinerario from '../Intinerario'
import Lugar from '../Lugar'
import Hospedaje from '../Hospedaje'
import Vestimenta from '../Vestimenta'
import Galeria from '../Galeria'
import Confirmacion from '../Confirmacion'

function Invitacion() {
  return (
    <>
      <CivilSection />    
      <ContadorSection />   
      <Intinerario/>
      <Lugar/>   
      <Hospedaje/>
      <Confirmacion/>
      <Vestimenta/>
      <Galeria/>
    </>
  );
}

export default Invitacion;