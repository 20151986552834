import React from 'react';
import '../App.css';
import './Vestimenta.css';
import Img1 from '../assets/images/formal.png'
import Img2 from '../assets/images/sobre.png'



function HeroSection() {

  return (
    <div className='Vestimenta-container'> 
      <h1>Código de vestimenta</h1>
      <br/>
      <p>Formal</p>
      <br/>
      <br/>
      <br/>
      <br/>
      <div className='vestimenta-img'><img src={Img1} alt="Imag 1" /></div>

      
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>

      <h1>Regalos</h1>
      <br/>      
      <p className='vestimenta-p'>Su presencia en nuestra boda será el mejor regalo! Pero si desean darnos otro obsequio, este lo pueden hacer en efectivo en la caja de sobres el día del evento. De igual manera si su deseo es hacerlo mediante transferencia les dejamos los datos de nuestra cuenta bancaria.</p>
      <br/>
      <br/>
      <h2>Transferencia Bancaria</h2>
      <p className='vestimenta-banco'>Banco: Santander</p>
      <p className='vestimenta-banco'>No. Tarjeta: 5579 1004 0254 0650</p>
      <p className='vestimenta-banco'>Melissa Jael Ibarra Gaona </p>
      <br/> <br/>
      <br/>
      <br/> <br/>
      <br/>
      <br/>

    </div>
  );
}

export default HeroSection;