import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import videoBg from '../assets/videos/SavetheDatev2.mp4'


  const images  = [
    "../assets/images/img-home.jpeg",
    "../assets/images/img-home2.jpeg",
    "../assets/images/img-home3.jpeg",
    "../assets/images/img-home4.jpeg",
    "../assets/images/img-home5.jpeg",
    "../assets/images/img-home6.jpeg",
    "../assets/images/img-home7.jpeg",
  ];


function HeroSection() {

  return (
    <div className='hero-container' id='container'> 
      
      <h1>SAVE THE DATE</h1>
      <p className='nombres'>Abraham & Melissa</p>
      <p>6 · Abril · 2024</p>
      <p>Santiago, Nuevo León</p>
     
    </div>
  );
}

export default HeroSection;