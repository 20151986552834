import React from 'react';
import Navbar from './Components/Navbar';
import './App.css';
import Home from './Components/pages/Home';
import Invitacion from './Components/pages/Civil';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
    <Router>
    <Navbar />
      <Routes>
        <Route path='/' exact element={ <Home />}></Route>        
        <Route path='/Invitacion' exact element={ <Invitacion />}></Route>
      </Routes>
    </Router>
    </>
  );
}

export default App;
