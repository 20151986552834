import React, { useEffect, useState } from 'react';
import '../App.css';
import './Confirmacion.css';
import { View } from 'react-native';
import axios from 'axios';
import {Spinner} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

import toast, { Toaster } from 'react-hot-toast';


function Confirmacion() {

    
    const queryParameters = new URLSearchParams(window.location.search)
    const Id = queryParameters.get("Id")
    const [userData, setUserData] = useState(null);    
    const [selectOptions, setSelectOptions] = useState([]);
    const items = [];
    const [Loading, setLoading] = useState(false);

    const [confirm, setconfirm] = useState({
      CantConfirmados: 1,
      Confirmacion: 1,
      FechaConfimacion: Date.now()
    });

    const [confirmNo, setconfirmNo] = useState({
      CantConfirmados: 0,
      Confirmacion: 0,
      FechaConfimacion: Date.now()
    });


    
        useEffect(() => {

          //GetData();

          // Función para realizar la solicitud a la API
          const fetchUserData = async () => {
            try {
              // Reemplaza 'URL_DE_TU_API' con la URL real de tu API
              const response = await axios.get(`https://abraham-melissa.com/api/index.php/`+Id).then();

              
              // Actualiza el estado con los datos recibidos
              setUserData(response.data);

              const numericOptions = Array.from({ length: response.data.CantInvitados }, (_, index) => ({
                id: index + 1,
                value: index + 1,
                label: `Persona(s) ${index + 1}`,
              }));

              setSelectOptions(numericOptions);

              
            } catch (error) {
              console.error('Error al obtener los datos del usuario:', error);
            }
          };

          // Llama a la función de solicitud al cargar la página
          fetchUserData();

 
      }, []);




      const handleChange = (e) => {
        setconfirm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      };


      const UpdateNo = async id => {

        await setconfirmNo(prevState => ({
          ...prevState, // Mantén los valores existentes
          CantConfirmados: 0,          
          Confirmacion: 0,
        }));

        setLoading(true);
        setTimeout(() => {
              
          axios.put(`https://abraham-melissa.com/api/index.php/${Id}/edit`, confirmNo).then(function(response){

          
            if(response.data.status === 1)
            {
              setLoading(false);
              setTimeout(() => {
                
              toast(response.data.message);
              }, 1000);
            }
            else{
              alert("Hubo un error en la confrimacion porfavor intentar mas tarde, si el problema persiste contactar al administrador")
              console.log(response)
            }

          });
        }, 3000);
        
      

      };

      const Update = id => {

        setLoading(true);
        setconfirm((prev) => ({ ...prev, 
          FechaConfimacion: Date.now() }));

        axios.put(`https://abraham-melissa.com/api/index.php/${Id}/edit`, confirm).then(function(response){

        //axios.put(`https://abraham-melissa.com/api/index.php/${Id}/edit`, confirm).then(function(response){

          console.log(response.data)
          if(response.data.status === 1)
          {
            
            setLoading(false);
            setTimeout(() => {
              
            toast(response.data.message);
            }, 1000);
          }
          else{
            
          setLoading(false);
            alert("Hubo un error en la confrimacion porfavor intentar mas tarde, si el problema persiste contactar al administrador")
            console.log(response)
          }

        });
      }



  return (
    <View>
            {Loading ? 

            <div className='Confirmacion-container'>
            <div className='divPLoad'>
              <div className='divHLoad'>
              <div className='Texto-con-fondo'>
                <p>Porfavor de esperar....</p>
                <Spinner className='spinnerReactstrap' color='primary' />
              </div>
              </div>
            </div>
            
            <div><Toaster/></div>
            </div> :  

            userData ? 
              <div className='Confirmacion-container'>
                <h1>Confirmacion de Asistencia</h1>
                <div className='Texto-con-fondo'>
                  <p>¡Queremos compartir este momento tan esperado contigo!</p>
                  <p>Por favor ayúdanos confirmando tu asistencia.</p>
                  <p>- No niños -</p>
                </div> 
                <br/>
  
                <div>
                    <table className='Tabla-Confirmacion'>
                            <tr className='confirmacion-table'>
                                <td className='nombreinv'>{userData.Familia}</td>
                                <td> {userData.CantInvitados} Pase(s)</td>
                            </tr>
                      <tr>
                        <td className='confirmacion-table'> 
                        
                          <p>Cantidad de Confirmados </p>
                        
                        </td>
                        <td className='confirmacion-invitados'>
                          <select  label="Selecciona Cauntos Invitados Confirmaras" name="CantConfirmados"
                            onChange={handleChange}>
                            {selectOptions.map((option) => (
                              <option key={option.id} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                        </td>
                      </tr>
                      <tr>
                        <td>    
                          <br/>     
                          <br/>  
                          <br/>             
                        <button className='btnNoConfirmar' onClick={() => UpdateNo()}>No Asistiré</button>
                        </td>
                        <td>    
                          <br/>  
                          <br/>  
                          <br/>                    
                        <button className='btnConfirmar' onClick={() => Update()}>Confirmar Asistencia</button>
                        </td>
                      </tr>
                    </table>
                  </div>
  
                  
                  
                  <div><Toaster/></div>
          
              <br/>
              <br/>
              <br/>    
              <br/>
              <br/>
              <br/>
              <br/>
                
              </div>
              
              : (
                <p>Cargando información...</p>
              ) 
          
          }
            
        </View>
    
  );
}

export default Confirmacion;