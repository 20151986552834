import React from 'react';
import '../App.css';
import './Hospedaje.css';




function Hospedaje() {

    const redirigirAUrl = () => {
        // Reemplaza 'https://www.ejemplo.com' con la URL a la que deseas redirigir.
        window.location.href = 'https://www.airbnb.mx/s/Santiago--Nuevo-Le%C3%B3n--M%C3%A9xico/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2023-09-01&monthly_length=3&price_filter_input_type=0&price_filter_num_nights=1&channel=EXPLORE&search_type=user_map_move&query=Santiago%2C%20N.L.&place_id=ChIJL5HBUZPOYoYRJZ-Jlr-dbHU&date_picker_type=calendar&checkin=2024-04-06&checkout=2024-04-07&adults=2&source=structured_search_input_header&ne_lat=25.510736901932155&ne_lng=-100.0932065444843&sw_lat=25.400538104057564&sw_lng=-100.20293866894502&zoom=13.120664417266738&zoom_level=13.120664417266738&search_by_map=true';
      };


  return (
    <div className='Hospedaje-container'> 
      <div className='info-hospedaje'>
      <p className='titulo-hospedaje'>Hospedaje Sugerido</p>
      <br/>
      <p>Nos encantaría que te divirtieras con nosotros sin preocupaciones, por lo cuál recomentamos tener un conductor designado, trasladarse a traves de algun servicio de plataforma (Uber, Didi, Taxi) o bien a continuación te compartimos algunos hospedajes cercanos al evento para que puedan llegar a descansar.</p>
      <p>Deseamos que disfruten tanto como nosotros este día especial y sobre todo nos importa su seguridad, hay que recordar que se tiene que pasar por carretera y que además existen antialcoholicas, por lo cuál le rogamos tomen algunas de las recomendaciones sugeridas.</p>

      </div>
      <br/>
      <div className='hoteles'>
        <h3>Hoteles</h3>
        <br/>
        <ul>
            <li><a href='https://maps.app.goo.gl/si6NFqUFZjmfPgxW6' className='linkhotel'>Casa Praxedis</a></li>
            <li><a href='https://maps.app.goo.gl/shhf1L8kbEqGMAmaA' className='linkhotel'>Gamma Monterrey Rincon de Santiago</a></li>
        </ul>
        
        <br/>        
        <br/>
        <h3>Airbnb</h3>
        <br/>
        <p>Lugares sugeridos según fecha y ubicación</p>
        <br/>
        <br/>
        <button className='btnairbnb' onClick={redirigirAUrl}>Ir a Airbnb</button>
      </div>
      
    </div>
  );
}

export default Hospedaje;