import React, { useState, useCallback } from 'react';
import  PhotoAlbum from "react-photo-album";
import './Galeria.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Galeria = () => {

  const photos = [
    { src: require("../assets/images/Galeria1.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria2.jpeg") , width: 4160, height: 2774 },
    { src: require("../assets/images/Galeria3.jpeg") , width: 2338, height: 4160 },
    { src: require("../assets/images/Galeria4.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria5.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria6.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria7.jpeg") , width: 4160, height: 2773 },
    { src: require("../assets/images/Galeria8.jpeg") , width: 4160, height: 2338 },
    { src: require("../assets/images/Galeria9.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria10.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria11.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria12.jpeg") , width: 4160, height: 2774 },
    { src: require("../assets/images/Galeria13.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria14.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria15.jpeg") , width: 4160, height: 2774 },
    { src: require("../assets/images/Galeria16.jpeg") , width: 4160, height: 2774 },
    { src: require("../assets/images/Galeria17.jpeg") , width: 4160, height: 2774 },
    { src: require("../assets/images/Galeria18.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria19.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria20.jpeg") , width: 2774, height: 4160 },
    { src: require("../assets/images/Galeria21.jpeg") , width: 4160, height: 2774 },
    { src: require("../assets/images/Galeria22.jpeg") , width: 2774, height: 4160 },
  ];


  const [index, setIndex] = useState(-1);

  

  return (
    <div className='Galeria-container'>
      <h1>Galeria de Fotos</h1>
      <br/>
      {/* <PhotoAlbum layout="rows" photos={photos} /> */}
      <PhotoAlbum photos={photos} layout="rows" targetRowHeight={300} onClick={({ index }) => setIndex(index)} />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};

export default Galeria;
