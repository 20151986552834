import React from 'react';
import '../App.css';
import { Button } from './Button';
import './Contador.css';
import videoBg from '../assets/videos/SavetheDatev2.mp4';
import {useRef, useEffect} from 'react';
import Timer from './Timer';

function ContadorSection() {

    

  return (
    <div className='Contador-container'> 
      
      <p>Queremos invitarles a que compartan con nosotros el momento más importante de nuestras vidas, en el cual ante Jehová y ustedes, comenzaremos un camino unidos en matrimonio.</p>
      <h1>The Wedding Day</h1>
      <br/><br/>
      <p className='fecha'>Sábado 06 de Abril del 2024</p>

    <section>
    <Timer/>
    </section>
    </div>
  );
}

export default ContadorSection;