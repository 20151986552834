import React, {useEffect} from 'react'
import './Intinerario.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Img1 from '../assets/images/civil.png'
import Img2 from '../assets/images/discurso.png'
import Img3 from '../assets/images/snack.png'
import Img4 from '../assets/images/recepcion.png'
import Img5 from '../assets/images/fin.png'


function Intinerario(){

    useEffect(() =>{
        AOS.init({duration: 2000});

    }, [])


    return(
        <div className='Intinerario-container'>
            
            <div className='animation' data-aos="fade-right"><img src={Img1} alt="Civil" /></div>
            <br/><br/>
            <div className='animation' data-aos="fade-left"><img src={Img2} alt="Discurso" /></div>
            <br/><br/>
            <div className='animation' data-aos="fade-right"><img src={Img3} alt="Snack" /></div>
            <br/><br/>
            <div className='animation' data-aos="fade-left"><img src={Img4} alt="Recepcion" /></div>
            <br/><br/>
            <div className='animation' data-aos="fade-right"><img src={Img5} alt="Fin" /></div>
            <br/><br/>
            
            
        </div>
    )


}

export default Intinerario;