import React from 'react';
import { useState, useEffect } from 'react';

const Timer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = new Date('04/06/2024 07:30 PM');

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timer" role="timer">

        <table align="center" width="100%">
            <tr>
            <td><span id="day">{days < 10 ? "0" + days : days}</span></td>
        <th>•</th>
            <td><span id="hours">{hours < 10 ? "0" + hours : hours}</span></td>
        <th>•</th>
            <td><span id="minutes">{minutes < 10 ? "0" + minutes : minutes}</span></td>
        <th>•</th>
            <td><span id="seconds">{seconds < 10 ? "0" + seconds : seconds}</span></td>
            </tr>
            <tr >
            <th>Días</th>
        <th></th>
            <th>Horas</th>
        <th></th>
            <th>Minutos</th>
        <th></th>
            <th>Segundos</th>
            </tr>
        </table>
    </div>
  );
};

export default Timer;